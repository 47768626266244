<template>
  <div class="d-flex flex-row">
    <!--begin::Content-->
    <div class="flex-row-fluid">
      <span v-if="companyInfo.id === undefined">
        <b-spinner variant="light" label="Spinning"></b-spinner>
      </span>
      <span v-if="companyInfo.id !== undefined">
        <company-information
          v-bind:companyinfo="companyInfo"
          v-bind:countries="countries"
          v-bind:sectors="sectors"
        >
        </company-information>
      </span>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CompanyService from "@/core/services/company.service";
import GeneralService from "@/core/services/general.service";
import CompanyInformation from "@/view/pages/company-profile/profile-comp/company-information";

export default {
  name: "company-profile",
  data() {
    return {
      tabIndex: 0,
      companyInfo: {},
      countries: [],
      sectors: []
    };
  },
  components: {
    CompanyInformation
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Company Profile" }]);
    const companyId = this.$store.state.auth.user.company_id;
    GeneralService.sectors().then(response => {
      this.sectors = response.data;
    });

    GeneralService.countries().then(response => {
      this.countries = response.data;
    });

    CompanyService.get(companyId).then(response => {
      this.companyInfo = response.data;
    });
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll('[data-toggle="tab"]');
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    getStates(countryId) {
      GeneralService.statesByCountry(countryId).then(response => {
        this.states = response.data;
      });
    },
    getCities(stateId) {
      GeneralService.citiesByState(stateId).then(response => {
        this.cities = response.data;
      });
    }
  },
  computed: {
    ...mapGetters(["currentUserPhoto"])
  }
};
</script>
