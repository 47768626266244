<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Company Information
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Update your company informaiton</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
      </div>
    </div>
    <form class="form">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Company Logo</label
          >
          <div class="col-lg-9 col-xl-6">
            <label
              class="border w-40 img-placeholder"
              data-action="change"
              data-toggle="tooltip"
              title=""
              data-original-title="Change avatar"
            >
              <img class="img-preview" v-if="photo" :src="photo" />
              <template v-if="$v.logo.$error">
                <span
                  class="form-text text-danger img-val-msg"
                  v-if="!$v.logo.validateLogo"
                >
                  logo cannot be larger than 2MB
                </span>
              </template>
              <input
                class="d-none"
                type="file"
                name="logo"
                ref="logo"
                id="logo"
                accept="image/jpeg, image/jpg, image/png"
                v-on:input="handleFileUpload()"
                @change="onFileChange($event)"
              />
            </label>
            <span class="form-text text-muted"
              >Allowed file types: png, jpg, jpeg.</span
            >
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Company Name *</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="text"
              :class="{ 'is-invalid': $v.name.$error }"
              v-model.trim="$v.name.$model"
            />
            <template v-if="$v.name.$error">
              <small class="text-danger" v-if="!$v.name.required">
                company name is required.
              </small>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Company Sector *</label
          >
          <div class="col-lg-9 col-xl-6">
            <multiselect
              v-model="$v.sector.$model"
              :options="sectors"
              track-by="name"
              label="name"
              placeholder="Select a sector"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{
                option.name
              }}</template>
            </multiselect>
            <template v-if="$v.sector.$error">
              <small class="text-danger" v-if="!$v.sector.required">
                company sector is required.
              </small>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Company Website</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              placeholder="e.g http://www.abccorp.com"
              v-model.trim="website"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Country *</label
          >
          <div class="col-lg-9 col-xl-6">
            <multiselect
              :multiple="false"
              v-model="$v.country.$model"
              :options="countries"
              track-by="name"
              label="name"
              placeholder="Select a country"
              @select="countryChange"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{
                option.name
              }}</template>
            </multiselect>
            <template v-if="$v.country.$error">
              <small class="text-danger" v-if="!$v.country.required">
                country is required.
              </small>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >State/Province *</label
          >
          <div class="col-lg-8 col-xl-6">
            <multiselect
              v-model="$v.state.$model"
              :options="states"
              track-by="name"
              label="name"
              placeholder="Select a state"
              @select="stateChange"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{
                option.name
              }}</template>
            </multiselect>
            <template v-if="$v.state.$error">
              <small class="text-danger" v-if="!$v.state.required">
                state is required.
              </small>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >City</label
          >
          <div class="col-lg-9 col-xl-6">
            <multiselect
              v-model="city"
              :options="cities"
              track-by="name"
              label="name"
              placeholder="Select a city"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{
                option.name
              }}</template>
            </multiselect>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Address</label
          >
          <div class="col-lg-9 col-xl-6">
            <textarea
              placeholder="Company Address"
              :class="{ 'is-invalid': $v.address.$error }"
              v-model.trim="$v.address.$model"
              class="form-control form-control-lg form-control-solid"
              type="text"
              rows="3"
            ></textarea>
            <template v-if="$v.address.$error">
              <small class="text-danger" v-if="!$v.address.required">
                company address is required.
              </small>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
            >Action Party Type</label
          >
          <div class="col-lg-9 col-xl-6">
            <multiselect
              v-model="$v.userGroup.$model"
              :options="usergroups"
              track-by="name"
              label="name"
              placeholder="Select an action party type"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{
                option.name
              }}</template>
            </multiselect>
            <template v-if="$v.userGroup.$error">
              <small class="text-danger" v-if="!$v.userGroup.required">
                Action party type is required.
              </small>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-4"></label>
          <div class="col-lg-8 col-xl-6">
            <b-form-checkbox
              switch
              size="lg"
              v-model="$v.isMultipleLocations.$model"
              >Do you have multiple locations</b-form-checkbox
            >
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import GeneralService from "@/core/services/general.service";
import CompanyService from "@/core/services/company.service";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import { UPDATE_COMPANY } from "@/core/services/store/auth.module";

const validateLogo = value => {
  if (!value) {
    return true;
  }
  let file = value;
  const buffer = Buffer.from(file.substring(file.indexOf(",") + 1));
  var size = buffer.length / 1e6;
  return size < 2;
};
export default {
  name: "company-information",
  components: {
    Multiselect
  },
  props: {
    companyinfo: {
      type: Object
    },
    countries: {
      type: Array
    },
    sectors: {
      type: Array
    }
  },
  data() {
    return {
      logo: null,
      displayLogo: null,
      name: "",
      address: "",
      website: "",
      isMultipleLocations: false,
      state: null,
      city: null,
      country: null,
      sector: null,
      userGroup: null,
      states: [],
      cities: [],
      usergroups: [
        {
          id: 1,
          name: "group"
        },
        {
          id: 2,
          name: "user"
        }
      ]
    };
  },
  validations: {
    logo: { validateLogo },
    sector: { required },
    name: { required },
    address: { required },
    country: { required },
    state: { required },
    userGroup: { required },
    isMultipleLocations: { required }
  },
  mounted() {
    console.clear();
    if (this.companyinfo !== undefined) {
      this.displayLogo = this.companyinfo.logo;
      this.name = this.companyinfo.name;
      this.address = this.companyinfo.address;
      this.website = this.companyinfo.website;
      this.country = this.companyinfo.country;
      this.getStates(this.companyinfo.country_id);
      this.getCities(this.companyinfo.state_id);
      this.sector = this.companyinfo.company_sector;
      this.state = this.companyinfo.state;
      this.city = this.companyinfo.city;
      this.isMultipleLocations = this.companyinfo.is_multiple_locations;
      const selectedGroup = this.usergroups.find(
        g => g.name === this.companyinfo.action_party_type
      );
      this.userGroup = selectedGroup;
    }
  },
  methods: {
    countryChange(option) {
      if (option !== null) {
        this.cities = [];
        this.states = [];
        this.getStates(option.id);
        this.state = null;
        this.city = null;
      }
    },
    stateChange(option) {
      if (option !== null) {
        this.getCities(option.id);
        this.city = null;
      }
    },
    save() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const payload = {
          open: true,
          sector_id: this.sector.id,
          name: this.name,
          logo: this.logo,
          website: this.website,
          address: this.address,
          country_id: this.country.id,
          state_id: this.state.id,
          city_id: this.city == null ? null : this.city.id,
          action_party_type: this.userGroup.name,
          is_multiple_locations: this.isMultipleLocations
        };

        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        CompanyService.update(this.companyinfo.id, payload)
          .then(response => {
            console.log(response.data.company);
            this.$store.dispatch(UPDATE_COMPANY, response.data.company);
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            this.makeToast(
              "success",
              "Company Information Saved",
              response.data.message
            );
          })
          .catch(error => {
            if (error.status == 422) {
              let errors = Object.values(error.data.errors);
              errors = errors.flat();
              this.makeToast("danger", "Validation Error", errors[0]);
            } else if (error.status == 400) {
              this.makeToast("danger", error.statusText, error.data.message);
            } else {
              this.makeToast(
                "danger",
                "Oops",
                "An unexpected exception has occured. Please give it a minute and refresh page"
              );
            }
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },
    cancel() {},
    getStates(countryId) {
      GeneralService.statesByCountry(countryId).then(response => {
        this.states = response.data;
      });
    },
    getCities(stateId) {
      GeneralService.citiesByState(stateId).then(response => {
        this.cities = response.data;
      });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.displayLogo = URL.createObjectURL(file);
      this.logo = URL.createObjectURL(file);
    },
    handleFileUpload() {
      const file = this.$refs.logo.files[0];
      this.getBase64(file).then(data => {
        this.logo = data;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  computed: {
    ...mapGetters(["currentUser", "layoutConfig"]),
    photo() {
      return this.displayLogo == null
        ? this.layoutConfig("placeholder")
        : this.displayLogo;
    }
  }
};
</script>
